import React from "react"
import { RichText } from "prismic-reactjs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import { ContentSection } from "../components/ContentSection"
import { linkResolver } from "../utils/linkResolver"
import LinkedInIcon from "../images/linkedin-logo-button.svg"
import TwitterIcon from "../images/twitter-logo-button.svg"
import TopSubNav from "../components/TopSubNav"
import { formatRegularLinks } from "../hooks/useFormattedRegularLinks"

export const query = graphql`
  query ProfileQuery($uid: String) {
    prismic {
      allProfiles(uid: $uid) {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            background_image
            avatar
            _meta {
              uid
            }
            full_bio
            name
            position
            short_description
            email_address
            address
            telephone_number
            linkedin_profile_url
            twitter_profile_url
          }
        }
      }
      allTeams {
        edges {
          node {
            top_sub_nav {
              nav_item
            }
            page_title
            body {
              ... on PRISMIC_TeamBodyManagement_team {
                primary {
                  team_name
                }
              }
              ... on PRISMIC_TeamBodyOther_team {
                primary {
                  team_name
                }
              }
            }
          }
        }
      }
    }
  }
`

const HeroImage = styled("div")`
  height: 600px;
  width: 100%;
  background-color: #454545;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: ${props =>
      props.imageUrl ? `url(${props.imageUrl})` : "none"};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    max-width: 1920px;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 480px) {
    height: 400px;
  }

  .profile-container {
    flex: 0 1 1200px;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .profile-inner-container {
    display: flex;
    align-items: center;
    z-index: 1;
    padding: 1rem;
    flex: 0 0 auto;

    h1,
    p {
      margin: 0;
      color: white;
    }

    p {
      font-size: 0.9rem !important;
    }

    .avatar-container {
      position: relative;
      width: 100px;
      height: 100px;
    }

    .avatar-circle {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      border: solid 3px #0099cb;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .info {
      flex: 1;
      margin-left: 1rem;
    }
  }
`

export const Avatar = styled("div")`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: ${props =>
    props.imageUrl ? `url(${props.imageUrl})` : "none"};
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
`

const StyledContentSection = styled(ContentSection)`
  a {
    color: #0099cb;
  }

  .inner-container {
    display: flex;

    @media screen and (max-width: 600px) {
      display: block;
    }
  }

  .content-wrapper {
    flex: 1;
    margin-right: 2rem;

    @media screen and (max-width: 600px) {
      margin-right: 0;
    }
  }

  .card-wrapper {
    flex: 0 0 300px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`

const ProfileCard = styled("div")`
  background: white;
  width: 100%;
  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  padding: 1rem;

  .profile-card-name {
    font-size: 1.25rem;
    margin: 1rem 0 0;
    font-weight: 400;
  }

  p {
    font-size: 0.9rem;
  }

  .profile-social-links {
    display: flex;

    a {
      margin-right: 0.5rem;
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allProfiles.edges.slice(0, 1).pop()
  if (!doc) return null

  const teamsData = data.prismic.allTeams.edges.slice(0, 1).pop()
  const externalScrollLinks = teamsData.node.body.map(b => ({
    id: b.primary.team_name[0].text.replace(" ", "_"),
    label: b.primary.team_name[0].text,
    isVisible: false,
    url: `/team#${b.primary.team_name[0].text.replace(" ", "_")}`,
  }))

  const formattedRegularLinks = formatRegularLinks(teamsData.node.top_sub_nav)

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node && doc.node.name && doc.node.name[0] && doc.node.name[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    (doc.node &&
      doc.node.short_description &&
      doc.node.short_description[0] &&
      doc.node.short_description[0].text) ||
    ""
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={doc.node.background_image && doc.node.background_image.url}
      />
      <TopSubNav
        title={
          teamsData.node.page_title &&
          RichText.asText(teamsData.node.page_title)
        }
        externalScrollLinks={externalScrollLinks}
        regularLinks={formattedRegularLinks}
      />
      <HeroImage
        imageUrl={
          doc.node.background_image ? doc.node.background_image.url : ""
        }
      >
        <div className="profile-container">
          <div className="profile-inner-container">
            <div className="avatar-container">
              <span className="avatar-circle" />
              <Avatar imageUrl={doc.node.avatar && doc.node.avatar.url} />
            </div>
            <div className="info">
              <h1 className="name">
                {doc.node.name && RichText.asText(doc.node.name)}
              </h1>
              <p className="title">
                {doc.node.position && RichText.asText(doc.node.position)}
              </p>
              <p className="description">
                {doc.node.short_description &&
                  RichText.asText(doc.node.short_description)}
              </p>
            </div>
          </div>
        </div>
      </HeroImage>
      <StyledContentSection>
        <div className="inner-container">
          <div className="content-wrapper">
            {RichText.render(doc.node.full_bio, linkResolver)}
          </div>
          <div className="card-wrapper">
            <ProfileCard>
              <h3 className="profile-card-name">
                {doc.node.name && RichText.asText(doc.node.name)}
              </h3>
              <p className="profile-card-title" style={{ fontWeight: 500 }}>
                {doc.node.position && RichText.asText(doc.node.position)}
              </p>
              {doc.node.address && RichText.render(doc.node.address)}
              {doc.node.telephone_number &&
                RichText.asText(doc.node.telephone_number) && (
                  <p className="profile-card-phone">
                    Tel:{" "}
                    <a
                      href={`tel:${RichText.asText(doc.node.telephone_number)}`}
                    >
                      {RichText.asText(doc.node.telephone_number)}
                    </a>
                  </p>
                )}
              {doc.node.email_address &&
                RichText.asText(doc.node.email_address) && (
                  <p className="profile-card-email">
                    E-mail:{" "}
                    <a
                      href={`mailto:${RichText.asText(doc.node.email_address)}`}
                    >
                      {RichText.asText(doc.node.email_address)}
                    </a>
                  </p>
                )}
              <div className="profile-social-links">
                {doc.node.linkedin_profile_url &&
                  RichText.asText(doc.node.linkedin_profile_url) && (
                    <a
                      href={RichText.asText(doc.node.linkedin_profile_url)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon />
                    </a>
                  )}
                {doc.node.twitter_profile_url &&
                  RichText.asText(doc.node.twitter_profile_url) && (
                    <a
                      href={RichText.asText(doc.node.twitter_profile_url)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  )}
              </div>
            </ProfileCard>
          </div>
        </div>
      </StyledContentSection>
    </Layout>
  )
}
